import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "session-ii",
      "style": {
        "position": "relative"
      }
    }}>{`Session II`}<a parentName="h1" {...{
        "href": "#session-ii",
        "aria-label": "session ii permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`The expansion pack for DAOs, Zodiac is a collection of tools built according to an open standard. The Zodiac open standard enables DAOs to act more like constellations, connecting protocols, platforms, and chains, no longer confined to monolithic designs.`}</p>
    <p>{`We now understand why it's necessary to know about the constellation of meanings associated with cooperatives and DAOs: seeing clearly where we have come from allows us to envision where we want to go next. Now it's time to learn about how to actually navigate by these constellations ourselves.`}</p>
    <h2 {...{
      "id": "reading-the-signs",
      "style": {
        "position": "relative"
      }
    }}>{`Reading the Signs`}<a parentName="h2" {...{
        "href": "#reading-the-signs",
        "aria-label": "reading the signs permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <h3 {...{
      "id": "objective",
      "style": {
        "position": "relative"
      }
    }}>{`Objective`}<a parentName="h3" {...{
        "href": "#objective",
        "aria-label": "objective permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Learn more about the Zodiac approach to DAO tooling and deploy your own Zodiac mods.`}</p>
    <h3 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h3" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://gnosisguild.mirror.xyz/OuhG5s2X5uSVBx1EK4tKPhnUc91Wh9YM0fwSnC8UNcg"
        }}>{`Zodiac: The expansion pack for DAOs`}</a>{`, Kei Kreutler (2021); `}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://gnosis.github.io/zodiac"
        }}>{`Zodiac docs`}</a>{` (2021)`}</li>
    </ol>
    <h3 {...{
      "id": "application",
      "style": {
        "position": "relative"
      }
    }}>{`Application`}<a parentName="h3" {...{
        "href": "#application",
        "aria-label": "application permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <ul>
      <li parentName="ul">{`Zodiac: The expansion pack for DAOs (20 minute presentation plus 5-10 minute Q&A)`}
        <ul parentName="li">
          <li parentName="ul">{`Problem statement introduction`}</li>
          <li parentName="ul">{`Brief overview of current Zodiac collection of tools`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Zodiac Deployment (40 minutes plus 5 minute Q&A)`}
        <ul parentName="li">
          <li parentName="ul">{`Create a `}<a parentName="li" {...{
              "href": "https://gnosis-safe.io/app/welcome"
            }}>{`Gnosis Safe`}</a>{` on a test network (we recommend Sepolia)`}</li>
          <li parentName="ul">{`Deploy Zodiac Exit and Delay Modules in it, by navigating to the "Apps" section.`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "recording",
      "style": {
        "position": "relative"
      }
    }}>{`Recording`}<a parentName="h3" {...{
        "href": "#recording",
        "aria-label": "recording permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <Video src="https://www.youtube-nocookie.com/embed/2NL4nIVUnEA" mdxType="Video" />
    <p><img parentName="p" {...{
        "src": "/images/build/zodiac_suite.png",
        "alt": "Zodiac Suite"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      